<template>
  <LoadingDots v-if="$apollo.loading" />
  <HeaderStyled v-else>
    <div class="wrapper" :title="title">
      <span>{{ title }}</span>
    </div>
  </HeaderStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'
import { LoadingDots } from '@common/components'
import DASHBOARD_BASE_QUERY from '#/graphql/operations/dashboard/dashboardBase.gql'

const HeaderStyled = styled('header')`
  ${flexCenter}
  height: calc(100% - .5rem);
  justify-content: flex-start;
  padding: 0.25rem 0.5rem;
  color: ${props => props.theme.colors.navFontNormal};
  background: ${props => props.theme.colors.solidBG};
  font-size: 12px;
  @media (min-width: 768px) {
    height: calc(100% - 2rem);
    padding: 1rem;
    font-size: 14px;
  }
  > .wrapper {
    width: calc(100% - 20rem);
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: default;
  }
`

export default {
  components: {
    HeaderStyled,
    LoadingDots,
  },
  data() {
    return {
      dashboard: {},
    }
  },
  computed: {
    title() {
      const type = this.dashboard.type ? this.$t(`dashboard.types.${this.dashboard.type.toLowerCase()}`) : ''
      return this.$route.name === 'dashboard'
        ? `${this.dashboard.title} (${type})`
        : this.$t(`navigation.cockpit.${this.$route.name}`) ?? ''
    },
  },
  apollo: {
    dashboard: {
      query: DASHBOARD_BASE_QUERY,
      skip() {
        return this.$route.name !== 'dashboard' || !this.$route?.params?.id
      },
      variables() {
        return {
          id: this.$route?.params?.id,
        }
      },
    },
  },
}
</script>
