var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading ? _c('LoadingDots') : _c('HeaderStyled', [_c('div', {
    staticClass: "wrapper",
    attrs: {
      "title": _vm.title
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.title))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }